export function errorMessageFormatter(err: any): string {
  console.log(typeof err, err);
  if (typeof err === 'string') {
    return err;
  }

  const status: any = {
    '-1': 'Servidor não encontrado',
    400: 'Dados inválidos',
    401: 'Sem permissão de acesso',
    403: 'Sem permissão de acesso',
    404: 'Não encontrado',
    422: 'Dados inválidos'
  };
  switch ((err || {}).message) {
    case 'no-internet':
    case 'NETWORK_ERROR':
      return 'Sem conexão com a internet';
    case 'zipcode-not-found':
      return 'CEP não encontrado';

    case 'api-error':
      if (err.status === -1) {
        return 'Não conseguimos se comunicar com o servidor';
      }

      if (err.status === 400) {
        switch ((err?.data || {}).message) {
          case 'email-unavailable':
            return 'Email já está em uso';
          case 'username-unavailable':
            return 'Nome de usuário já está em uso';
          case 'document-unavailable':
            return 'Documento já está em uso';
          case 'invalid-credentials':
            return 'Credenciais inválidas';
          case 'bank-file-processing':
            return 'Não é possível enviar um arquivo de uma empresa enquanto processa um outro.';
          case 'non-standard-file':
            return 'Arquivo fora dos padrões CNAB 240';
          case 'no-payment-slips-from-return':
            return 'Nenhum dos primeiros retornos deste arquivo existem no sistema. Certifique-se de ter enviado o arquivo de REMESSA antes!';
          default:
            return `Dados inválidos: ${err.data?.message || ''}`;
        }
      }

      if (err.status === 403) {
        switch ((err?.data || {}).message) {
          case 'user-has-no-person':
            return 'O usuário não administra nenhuma pessoa física ou jurídica';
          case 'user-cant-access-person':
            return 'O usuário não tem acesso à esta pessoa';
          case 'not-permited':
            return 'Operação não permitida';
        }
      }

      if (err.status === 404) {
        switch ((err?.data || {}).message) {
          case 'issuer-not-found':
            return 'Emitente não encontrado';
          case 'no-bank-contract-found':
            return 'Contrato Bancário não encontrado';
          case 'issuer-has-no-addresses':
            return 'O emitente não possui endereços cadastrados';
        }
      }

      if (err.status === 409) {
        switch ((err?.data || {}).message) {
          case 'roles-required':
            return 'Função do usuário obrigatória';
          case 'invalid-roles':
            return 'Função inválida';
          case 'not-allowed-remove-current-user':
            return 'Não é permitido remover o próprio usuário';
          case 'bank-file-already-processed':
            return 'Este arquivo já foi enviado anteriormente...';
          case 'bank-file-processing':
            return 'Já existe um arquivo desta empresa sendo processado';
          case 'not-allowed-remove-sysAdmin':
            return 'Não é permitido remover o Sys Admin';
          case 'invalid-credentials':
            return 'Credenciais inválidas';
          case 'wrong-current-password':
            return 'Senha atual errada';
          case 'token-type-not-match':
            return 'Tipo do token não compatível';
          case 'token-expired':
            return 'Token expirado';
          case 'token-invalid':
            return 'Token inválido';
          case 'changing-folder-forbidden':
            return 'Mudança de pastas não permitida';
        }
      }

      return status[err.status] || 'Algo deu errado...';
    default:
      return 'Algo deu errado...';
  }
}
