import PermissionRoute from 'components/Shared/PermissionRoute';
import React, { memo, useCallback } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PageLoading from '../Shared/PageLoading';

const AdminPage = React.lazy(() =>
  import(
    /* webpackChunkName: "Admin" */
    /* webpackPrefetch: true */
    './Admin'
  )
);

const NewPasswordPage = React.lazy(() =>
  import(
    /* webpackChunkName: "Admin" */
    /* webpackPrefetch: true */
    './Public/NewPassword'
  )
);

const Pages = memo(() => {
  const renderEmpty = useCallback(() => <div />, []);
  const renderRedirect = useCallback(() => <Redirect to='/' />, []);

  return (
    <React.Suspense fallback={<PageLoading />}>
      <BrowserRouter>
        <Switch>
          <Route path='/nova-senha' exact component={NewPasswordPage} />
          <PermissionRoute path='/' component={AdminPage} />

          <Route path='/reload' exact render={renderEmpty} />
          <Route render={renderRedirect} />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
});

export default Pages;
