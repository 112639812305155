import { bindComponent } from 'helpers/rxjs-operators/bindComponent';
import { logError } from 'helpers/rxjs-operators/logError';
import { enRoles } from 'interfaces/models/user/user';
import React, { Fragment } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { tap } from 'rxjs/operators';
import authService from 'services/auth';

import PermissionHide from './PermissionHide';
import { Table } from '@material-ui/core';
import EmptyAndErrorMessages from './Pagination/EmptyAndErrorMessages';
import Toast from './Toast';
import { errorMessageFormatter } from '../../formatters/errorMessage';

interface IProps extends RouteProps {
  role?: enRoles;
}

export default class PermissionRoute extends Route<IProps> {
  constructor(props: IProps) {
    super(props);
    const search = props.location.search.split('?');
    let searchParams;
    if (search.length > 1) {
      searchParams = search
        .pop()
        .split('&')
        .map(param => {
          return param.split('=');
        })
        .reduce((arr, curr) => {
          return {
            ...arr,
            [curr[0]]: curr[1]
          };
        }, Object.assign({}));
    }

    this.state = { ...(this.state || {}), authParams: searchParams || {} };
  }

  componentDidMount() {
    super.componentDidMount && super.componentDidMount();

    authService
      .isAuthenticated()
      .pipe(
        logError(),
        bindComponent(this),
        tap(isAuthenticated => {
          if (isAuthenticated) return;
          authService.openLogin();
        })
      )
      .subscribe(isAuthenticated => {
        this.setState({ isAuthenticated });
      });

    if (!this.state.isAuthenticated && this.state.authParams && this.state.authParams.u && this.state.authParams.h) {
      authService
        .login(this.state.authParams.u, this.state.authParams.h)
        .pipe(logError(true))
        .subscribe(
          () => {},
          (error: any) => {
            Toast.error(errorMessageFormatter(error));
          }
        );
    }
  }

  render() {
    const { isAuthenticated } = this.state;

    if (!isAuthenticated) {
      return null;
    }

    return (
      <Fragment>
        <PermissionHide role={this.props.role}>{super.render()}</PermissionHide>

        <PermissionHide inverse role={this.props.role}>
          <Table>
            <EmptyAndErrorMessages
              colSpan={5}
              error={'Sem permissão de acesso'}
              hasData={false}
              onTryAgain={() => {
                window.location.href = '/';
              }}
            />
          </Table>
        </PermissionHide>
      </Fragment>
    );
  }
}
