import './assets/global.css';
import './yupLocale';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themes from 'assets/theme';
import ThemeContext, { IThemeContext, ThemesTypes } from 'assets/theme/context';
import Pages from 'components/Pages';
import Alert from 'components/Shared/Alert';
import Loader from 'components/Shared/Loader';
import Toast from 'components/Shared/Toast';
import React, { memo, useMemo, useState } from 'react';
import PageLoading from './components/Shared/PageLoading';

const Dialogs = React.lazy(() =>
  import(
    /* webpackChunkName: "Dialogs" */
    /* webpackPrefetch: true */
    'components/Dialogs'
  )
);
const App = memo(() => {
  const [currentTheme, setCurrentTheme] = useState<ThemesTypes>(
    (localStorage.getItem('app-theme') ?? 'light') as ThemesTypes
  );

  const themeContext = useMemo<IThemeContext>(() => {
    return {
      currentTheme,
      toogleTheme: () => {
        const newTheme: ThemesTypes = currentTheme === 'light' ? 'dark' : 'light';
        localStorage.setItem('app-theme', newTheme);
        setCurrentTheme(newTheme);
      }
    };
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={themeContext}>
      <MuiThemeProvider theme={themes[themeContext.currentTheme]}>
        <React.Suspense fallback={<PageLoading />}>
          <CssBaseline />
          <Dialogs />

          <Loader />

          <Alert.Global />
          <Toast.Global />

          <Pages />
        </React.Suspense>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
});

export default App;
