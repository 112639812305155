import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'transparent',
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  card: {
    maxWidth: '40%',
    minHeight: '20vh',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent'
  },
  loader: {
    width: 70,
    height: 70,
    color: theme.palette.primary.dark
  }
}));

const PageLoading = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={0} alignItems='center' justify='center'>
      <div className={classes.card}>
        <CircularProgress className={classes.loader} size='large' color='primary' />
      </div>
    </Grid>
  );
};
export default PageLoading;
